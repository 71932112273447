var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", "margin-top": "15px" } },
    [
      _vm.title
        ? _c("div", { staticClass: "card mb-1 table-title" }, [
            _c(
              "div",
              { staticClass: "card-header text-center" },
              [
                _c("i", {
                  staticClass: "fa fa-arrow-circle-left back-icon",
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                }),
                _c("b", [_vm._v(_vm._s(_vm.title))]),
                _c(
                  "v-popover",
                  {
                    staticClass: "d-none d-md-inline-block",
                    staticStyle: { position: "absolute", right: "15px" },
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "fa fa-question-circle",
                        staticStyle: { "margin-top": "0", "padding-top": "0" },
                      }),
                    ]),
                    _c("template", { slot: "popover" }, [
                      _vm._v(" Tap on green column headers to sort "),
                    ]),
                  ],
                  2
                ),
                _c(
                  "v-popover",
                  {
                    staticClass: "d-inline-block d-md-none",
                    staticStyle: { position: "absolute", right: "15px" },
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "fa fa-question-circle",
                        staticStyle: { "margin-top": "0", "padding-top": "0" },
                      }),
                    ]),
                    _c("template", { slot: "popover" }, [
                      _vm._v(
                        " Widen view by turning phone landscape and tap green column headers to sort "
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "d-md-none" }, [
        _vm.userInfo && _vm.userInfo.results && _vm.userInfo.results.length
          ? _c(
              "div",
              _vm._l(_vm.userInfo.results, function (user, i) {
                return _c("UserCard", {
                  key: i,
                  attrs: { user: user },
                  on: {
                    updateSort: function ($event) {
                      return _vm.onGetSortFromCard($event)
                    },
                  },
                })
              }),
              1
            )
          : _c("div", { staticClass: "card" }, [_vm._m(0)]),
      ]),
      _vm.userInfo && _vm.userInfo.results
        ? _c("div", { staticClass: "card d-none d-md-flex" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-bordered table-striped" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "text-center align-middle" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeSortTo("namedirectory")
                              },
                            },
                          },
                          [_vm._v("Name")]
                        ),
                      ]),
                      _c("th", { staticClass: "text-center align-middle" }, [
                        _vm._v("Email"),
                      ]),
                      _c("th", { staticClass: "text-center align-middle" }, [
                        _vm._v("Phone "),
                      ]),
                      _c("th", { staticClass: "text-center align-middle" }, [
                        _vm._v("State "),
                      ]),
                      _c("th", { staticClass: "text-center align-middle" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeSortTo("handicapindex")
                              },
                            },
                          },
                          [_vm._v("Handicap")]
                        ),
                      ]),
                      _c("th", { staticClass: "text-center align-middle" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeSortTo("parorbetteravg")
                              },
                            },
                          },
                          [_vm._v("Par+")]
                        ),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeSortTo("numrounds")
                              },
                            },
                          },
                          [_vm._v("Revolving Year's Round")]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.userInfo.results.length
                    ? _c(
                        "tbody",
                        _vm._l(_vm.userInfo.results, function (user) {
                          return _c("tr", [
                            _c(
                              "td",
                              { staticClass: "text-center align-middle" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: { to: `handicapLookup/${user.id}` },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        user.lastname + " " + user.firstname
                                      ) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center align-middle" },
                              [
                                _c(
                                  "a",
                                  { attrs: { href: `mailto:${user.email}` } },
                                  [_vm._v(_vm._s(user.email) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center align-middle" },
                              [
                                _vm._v(
                                  _vm._s(user.phonenum ? user.phonenum : "N/A")
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center align-middle" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(user.state ? user.state : "N/A")
                                  ),
                                ]),
                              ]
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  user.handicapindex ? user.handicapindex : 0
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(user.parorbetteravg)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(user.numrounds)),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _c("tbody", [_vm._m(1)]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.userInfo &&
      _vm.userInfo.results &&
      _vm.userInfo.results.length &&
      _vm.filters.size > 0
        ? _c("Page", {
            attrs: {
              nextPage: +_vm.userInfo.nextPage,
              page: +_vm.userInfo.page,
              totalPages: +_vm.userInfo.totalPages,
            },
            on: {
              updatePage: function ($event) {
                return _vm.onChangePageTo($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body text-text-center" }, [
      _c("b", [_vm._v("No Users")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-text-center", attrs: { colspan: "8" } }, [
        _c("b", [_vm._v("No Users")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }